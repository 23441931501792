<template>
    <div>
        <h1>I am from A component</h1>
        <div class="flex gap-2">
            <img src="https://picsum.photos/200/300">
            <img src="https://picsum.photos/200/300">
            <img src="https://picsum.photos/200/300">
            <img src="https://picsum.photos/200/300">
            <img src="https://picsum.photos/200/300">
        </div>
    </div>
</template>

<script>
export default {

}
</script>